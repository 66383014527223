<template>
  <aside class="page__sidebar">
    <div class="sidebar__balance" style="text-align: center">
      <span>Saldo atual</span>
      <h2 v-if="!loading">{{ balance }}</h2>
      <loading-small style="margin-top:0.5rem" v-else></loading-small>
    </div>

    <div class="sidebar__nav">
      <router-link
        class="sidebar__nav--anchor"
        :class="{ current: current == 1 }"
        to="/extratos"
        title="Extratos"
      >
        <i class="icon icon-extract"></i>
        Extratos
      </router-link>

      <router-link
        class="sidebar__nav--anchor"
        :class="{ current: current == 2 }"
        to="/transferencias"
        title="Transferências"
      >
        <i class="icon icon-transfers"></i>
        Transferências
      </router-link>
      <router-link
          class="sidebar__nav--anchor"
           :class="{ current: current == 3 }"
          to="/informacoes"
          title="Dados da entidade"
        >
        <i class="icon icon-heart"></i>
        Dados da entidade
      </router-link>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { loadingSmall: () => import('./loading-small') },
  data: () => ({
    modal: false,
    entities: [],
    form: {},
    selectedEntity: {},
    error: null,
    loading: true
  }),
  props: {
    current: Number
  },

  methods: {
    ...mapActions('balance', ['getBalance']),
    async fetchBalance () {
      this.loading = true
      this.error = null
      try {
        await this.getBalance()
      } finally {
        this.loading = false
      }
    },
    openModal (item) {
      this.selectedEntity = item
      this.modal = true
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
    ...mapGetters('balance', ['balance'])
  },
  mounted () {
    this.fetchBalance()
  }
}
</script>

<style></style>
